import "./locatii.css"


export default function Locatii() {


    return (
        <div className="locatii-div">
            <div className="locatii-title">
                <p>Locatii</p>
                <div className="locatii-decorator"></div>
            </div>
            <div className="container">
                <div className="text-cont">
                <p className="text-loc">Strada Batistei 11, Bucuresti 030167</p>
                </div>
                <div className="locatii-decorator"></div>
                <div className="map-cont">
                    <div >
                        <iframe width="100%" height="346" frameBorder="0" scrolling="no" marginHeight="0"
                                marginWidth="0"
                                src="https://maps.google.com/maps?width=100%25&amp;height=350&amp;hl=en&amp;q=Strada%20Batistei%2011+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                            <a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a></iframe>
                    </div>
                </div>
                <div className="text-cont">
                    <p className="text-loc">L - V : 7:00 - 16:00 </p>
                    <p className="text-loc"></p>
                </div>
            </div>
            <div className="container">
                <div className="text-cont">
                    <p className="text-loc">Strada Italiana 13, București 030167</p>
                </div>
                <div className="locatii-decorator"></div>
                <div className="map-cont">
                    <div >
                        <iframe width="100%" height="346" frameBorder="0" scrolling="no" marginHeight="0"
                                marginWidth="0"
                                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Strada%20Italiana%2013,%20Bucure%C8%99ti%20030167+(Roasted%20Coffee%20Shop)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                            <a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe>
                    </div>
                </div>
                <div className="text-cont">
                    <p className="text-loc">L - V : 7:00 - 16:00 </p>
                    <p className="text-loc">S : 9:00 - 14:00</p>
                </div>
            </div>
        </div>
    )
}