import "./soon.css"
import Navbar from "../menu/menu";
import {Link} from "react-router-dom";
export default function Soon()
{
    return(
        <div>
            <Navbar/>
            <div className="center-soon">
                <h1 className="heading-soon">Aceasta pagina nu este inca disponibila :(</h1>
                <button className="button-soon">
                    <p>
                        <Link className="link-soon" to="/">Back to homepage</Link>
                    </p>
                </button>
            </div>
        </div>
    )
}