import "./contaMain.css"
import Navbar from "../menu/menu";
import {useState} from "react";
import Items from "./items";
import {useNavigate} from "react-router-dom";
export default function ContaMain() {

    const [cafea, setCafea] = useState(0)
    const [cafeafaracofeina, setCafeaFaraCofeina] = useState(0)
    const [lapte, setLapte] = useState(0)
    const [alternativ, setAlternativ] = useState(0)
    const [sirop, setSirop] = useState(0)
    const [frisca, setFrisca] = useState(0)
    const [inghetata, setInghetata] = useState(0)
    const [gheata, setGheata] = useState(0)
    const [pahare, setPahare] = useState(0)
    const [capace, setCapace] = useState(0)
    const [suportcafea2x, setSuportCafea2x] = useState(0)
    const [suportcafea4x, setSuportCafea4x] = useState(0)
    const [bomboanecafea, setBomboaneCafea] = useState(0)
    const [servetele, setServetele] = useState(0)
    const [zaharalb, setZaharAlb] = useState(0)
    const [zaharbrun, setZaharBrun] = useState(0)
    const [paletine, setPaletine] = useState(0)
    const [paie, setPaie] = useState(0)
    const [bananabread, setBananaBread] = useState(0)
    const [sandwich, setSandwich] = useState(0)
    const [apaplata, setApaPlata] = useState(0)
    const [apaminerala, setApaMinerala] = useState(0)
    const [fentiman, setFentiman] = useState(0)
    const [melow, setMelow] = useState(0)
    const [jumex, setJumex] = useState(0)
    const [rubicon, setRubicon] = useState(0)
    const [energizante, setEnergizante] = useState(0)
    const [chirie, setChirie] = useState(0)
    const [curent, setCurent] = useState(0)
    const [intretinere, setIntretinere] = useState(0)
    const [salarii, setSalarii] = useState(0)
    const [costuriddd, setCosturiDDD] = useState(0)
    const [costurissmsu, setCosturiSSMSU] = useState(0)
    const [neprevazute, setNeprevazute] = useState(0)
    const [chai, setChai] = useState(0)
    const [cioco, setCioco] = useState(0)
    const [ciocoAlba, setCiocoAlba] = useState(0)
    const [ciocoNeagra, setCiocoNeagra] = useState(0)
    const [matcha, setMatcha] = useState(0)
    const [cookies, setCookies] = useState(0)


    let suma = 0
    const navigate = useNavigate()

    const changeCafea = e => {
        setCafea(e.target.value)
    }

        const changeCafeaFaraCofeina = e => {
            setCafeaFaraCofeina(e.target.value)
        }
    const changeFrisca = e => {
        setFrisca(e.target.value)
    }

    const changeChai = e => {
        setChai(e.target.value)
    }

    const changeMatcha = e => {
        setMatcha(e.target.value)
    }

    const changeCioco = e => {
        setCioco(e.target.value)
    }

    const changeCiocoAlba = e => {
        setCiocoAlba(e.target.value)
    }

    const changeCiocoNeagra = e => {
        setCiocoNeagra(e.target.value)
    }

    const changeCookies= e => {
        setCookies(e.target.value)
    }

    const changeLapte = e => {
        setLapte(e.target.value)
    }

    const changeAlternativ = e => {
        setAlternativ(e.target.value)
    }

    const changeSirop = e => {
        setSirop(e.target.value)
    }
    const changeInghetata = e => {
        setInghetata(e.target.value)
    }
    const changeGheata = e => {
        setGheata(e.target.value)
    }

    const changeSuportCafea2x = e => {
        setSuportCafea2x(e.target.value)
    }

    const changeSuportCafea4x = e => {
        setSuportCafea4x(e.target.value)
    }

    const changeServetele = e => {
        setServetele(e.target.value)
    }

    const changeZaharAlb = e => {
        setZaharAlb(e.target.value)
    }

    const changeZaharBrun = e => {
        setZaharBrun(e.target.value)
    }
    const changePaletine = e => {
        setPaletine(e.target.value)
    }
    const changeCapace = e => {
        setCapace(e.target.value)
    }
    const changePahare = e => {
        setPahare(e.target.value)
    }
    const changePaie = e => {
        setPaie(e.target.value)
    }
    const changeBomboaneCafea = e => {
        setBomboaneCafea(e.target.value)
    }
    const changeBananaBread = e => {
        setBananaBread(e.target.value)
    }

    const changeSandwich = e => {
        setSandwich(e.target.value)
    }

    const changeApaPlata = e => {
        setApaPlata(e.target.value)
    }

    const changeApaMinerala= e => {
        setApaMinerala(e.target.value)
    }

    const changeFentiman = e => {
        setFentiman(e.target.value)
    }

    const changeMelow = e => {
        setMelow(e.target.value)
    }

    const changeJumex = e => {
        setJumex(e.target.value)
    }

    const changeRubicon = e => {
        setRubicon(e.target.value)
    }

    const changeEnergizante = e => {
        setEnergizante(e.target.value)
    }

    const changeChirie= e => {
        setChirie(e.target.value)
    }

    const changeCurent = e => {
        setCurent(e.target.value)
    }

    const changeIntretinere = e => {
        setIntretinere(e.target.value)
    }

    const changeSalarii = e => {
        setSalarii(e.target.value)
    }

    const changeCosturiDDD = e => {
        setCosturiDDD(e.target.value)
    }

    const changeCosturiSSMSU= e => {
        setCosturiSSMSU(e.target.value)
    }

    const changeNeprevazute = e => {
        setNeprevazute(e.target.value)
    }

    const sumaZi = () => {
        suma += (cafea + lapte + alternativ + sirop)
        console.log(suma)
        navigate('/plot')
    }

    return(
        <div>
            <Navbar/>
           <div className="spatiu-conta">

           </div>
            <div className="conta-login-main">
                <div className="conta-title">
                    Contabilitate Tot
                </div>
                <div className="conta-line"></div>
                <div className="conta-content">
                    <p>
                        <label className="padding-label1-conta">
                            Cafea
                        </label>
                        <input onChange={changeCafea} className="margin-label-conta" type="text" name="cafea"
                               value={cafea}/>
                    <p>
                        <label className="padding-label-conta">
                            Lapte normal
                        </label>
                        <input onChange={changeLapte} className="margin-label-conta" type="text" name="lapte"
                               value={lapte}/>
                    </p>
                    <p>
                        <label className="padding-label3-conta">
                            Alternativ
                        </label>
                        <input onChange={changeAlternativ} className="margin-label-conta" type="text" name="lapte"
                               value={alternativ}/>
                    </p>
                    <p>
                        <label className="padding-label4-conta">
                            Sirop
                        </label>
                        <input onChange={changeSirop} className="margin-label-conta" type="text" name="sirop"
                               value={sirop}/>
                    </p>

                        <p>
                            <label className="padding-label11-conta">
                                Chai
                            </label>
                            <input onChange={changeChai} className="margin-label-conta" type="text" name="neprevazute"
                                   value={chai}/>
                        </p>

                        <p>
                            <label className="padding-label12-conta">
                                Matcha
                            </label>
                            <input onChange={changeMatcha} className="margin-label-conta" type="text" name="neprevazute"
                                   value={matcha}/>
                        </p>

                        <p>
                            <label className="padding-label13-conta">
                                Ciocolata
                            </label>
                            <input onChange={changeCioco} className="margin-label-conta" type="text" name="neprevazute"
                                   value={cioco}/>
                        </p>

                        <p>
                            <label className="padding-label14-conta">
                                Ciocolata A
                            </label>
                            <input onChange={changeCiocoAlba} className="margin-label-conta" type="text" name="neprevazute"
                                   value={ciocoAlba}/>
                        </p>

                        <p>
                            <label className="padding-label15-conta">
                                Ciocolata N
                            </label>
                            <input onChange={changeCiocoNeagra} className="margin-label-conta" type="text" name="neprevazute"
                                   value={ciocoNeagra}/>
                        </p>

                    <p>
                       <label className="padding-label16-conta">
                           Frisca
                       </label>
                        <input onChange={changeFrisca} className="margin-label-conta" type="text" name="frisca"
                               value={frisca}/>
                    </p>

                    <p>
                        <label className="padding-label17-conta">
                            Inghetata
                        </label>
                        <input onChange={changeInghetata} className="margin-label-conta" type="text" name="inghetata"
                               value={inghetata}/>
                    </p>

                    <p>
                        <label className="padding-label18-conta">
                            Gheata
                        </label>
                        <input onChange={changeGheata} className="margin-label-conta" type="text" name="gheata"
                               value={gheata}/>
                    </p>

                    <p>
                        <label className="padding-label19-conta">
                            Pahare
                        </label>
                        <input onChange={changePahare} className="margin-label-conta" type="text" name="pahare"
                               value={pahare}/>
                    </p>


                    <p>

                    <label className="padding-label20-conta">
                        Fara cofeina
                    </label>
                    <input onChange={changeCafeaFaraCofeina} className="margin-label-conta" type="text" name="cafeafaracofeinaina"
                    value={cafeafaracofeina}/>
                    </p>

                    <p>
                    <label className="padding-label21-conta">
                        Capace
                    </label>
                    <input onChange={changeCapace} className="margin-label-conta" type="text" name="capace"
                    value={capace}/>
                    </p>

                    <p>
                    <label className="padding-label22-conta">
                        Suport 2x
                    </label>
                    <input onChange={changeSuportCafea2x} className="margin-label-conta" type="text" name="suportcafea2x"
                    value={suportcafea2x}/>
                    </p>

                    <p>
                    <label className="padding-label23-conta">
                        Suport 4x
                    </label>
                    <input onChange={changeSuportCafea2x} className="margin-label-conta" type="text" name="suportcafea4x"
                    value={suportcafea4x}/>
                    </p>

                    <p>
                    <label className="padding-label24-conta">
                        Bomboane
                    </label>
                    <input onChange={changeBomboaneCafea} className="margin-label-conta" type="text" name="bomboanecafea"
                    value={bomboanecafea}/>
                    </p>

                    <p>
                    <label className="padding-label25-conta">
                        Servetele
                    </label>
                    <input onChange={changeServetele} className="margin-label-conta" type="text" name="servetele"
                    value={servetele}/>
                    </p>

                    <p>
                    <label className="padding-label26-conta">
                        Zahar Alb
                    </label>
                    <input onChange={changeZaharAlb} className="margin-label-conta" type="text" name="zaharalb"
                    value={zaharalb}/>
                    </p>

                    <p>
                    <label className="padding-label27-conta">
                        Zahar Brun
                    </label>
                    <input onChange={changeZaharBrun} className="margin-label-conta" type="text" name="zaharbrun"
                    value={zaharbrun}/>
                    </p>

                    <p>
                    <label className="padding-label28-conta">
                        Paletine
                    </label>
                    <input onChange={changePaletine} className="margin-label-conta" type="text" name="paletine"
                    value={paletine}/>
                    </p>

                    <p>
                    <label className="padding-label29-conta">
                        Paie
                    </label>
                    <input onChange={changePaie} className="margin-label-conta" type="text" name="paie"
                    value={paie}/>
                    </p>

                    <p>
                    <label className="padding-label30-conta">
                        Banana B
                    </label>
                    <input onChange={changeBananaBread} className="margin-label-conta" type="text" name="bananabread"
                    value={bananabread}/>
                    </p>

                        <p>
                            <label className="padding-label31-conta">
                                Cookies
                            </label>
                            <input onChange={changeCookies} className="margin-label-conta" type="text" name="bananabread"
                                   value={cookies}/>
                        </p>

                    <p>
                    <label className="padding-label32-conta">
                        Sandwich
                    </label>
                    <input onChange={changeSandwich} className="margin-label-conta" type="text" name="sandwich"
                    value={sandwich}/>
                    </p>

                    <p>
                    <label className="padding-label33-conta">
                        Apa Plata
                    </label>
                    <input onChange={changeApaPlata} className="margin-label-conta" type="text" name="apaplata"
                    value={apaplata}/>
                    </p>

                    <p>
                    <label className="padding-label34-conta">
                        Apa Minerala
                    </label>
                    <input onChange={changeApaMinerala} className="margin-label-conta" type="text" name="apaminerala"
                    value={apaminerala}/>
                    </p>

                    <p>
                    <label className="padding-label35-conta">
                        Fentiman
                    </label>
                    <input onChange={changeFentiman} className="margin-label-conta" type="text" name="fentiman"
                    value={fentiman}/>
                    </p>

                    <p>
                    <label className="padding-label36-conta">
                        Melow
                    </label>
                    <input onChange={changeMelow} className="margin-label-conta" type="text" name="melow"
                    value={melow}/>
                    </p>

                    <p>
                    <label className="padding-label37-conta">
                        Jumex
                    </label>
                    <input onChange={changeJumex} className="margin-label-conta" type="text" name="jumex"
                    value={jumex}/>
                    </p>

                    <p>
                    <label className="padding-label38-conta">
                        Rubicon
                    </label>
                    <input onChange={changeRubicon} className="margin-label-conta" type="text" name="rubicon"
                    value={rubicon}/>
                    </p>

                    <p>
                    <label className="padding-label39-conta">
                        Energizante
                    </label>
                    <input onChange={changeEnergizante} className="margin-label-conta" type="text" name="energizante"
                    value={energizante}/>
                    </p>

                    <p>
                    <label className="padding-label40-conta">
                        Chirie
                    </label>
                    <input onChange={changeChirie} className="margin-label-conta" type="text" name="chirie"
                    value={chirie}/>
                    </p>

                    <p>
                    <label className="padding-label41-conta">
                        Curent
                    </label>
                    <input onChange={changeCurent} className="margin-label-conta" type="text" name="curent"
                    value={curent}/>
                    </p>

                    <p>
                    <label className="padding-label42-conta">
                        Intretinere
                    </label>
                    <input onChange={changeIntretinere} className="margin-label-conta" type="text" name="intretinere"
                    value={intretinere}/>
                    </p>

                    <p>
                    <label className="padding-label43-conta">
                        Salarii
                    </label>
                    <input onChange={changeSalarii} className="margin-label-conta" type="text" name="salarii"
                    value={salarii}/>
                    </p>

                    <p>
                    <label className="padding-label44-conta">
                        DDD
                    </label>
                    <input onChange={changeCosturiDDD} className="margin-label-conta" type="text" name="costuriddd"
                    value={costuriddd}/>
                    </p>

                    <p>
                    <label className="padding-label45-conta">
                        SSM-SU
                    </label>
                    <input onChange={changeCosturiSSMSU} className="margin-label-conta" type="text" name="costurissmsu"
                    value={costurissmsu}/>
                    </p>

                    <p>
                    <label className="padding-label46-conta">
                        Neprevazute
                    </label>
                    <input onChange={changeNeprevazute} className="margin-label-conta" type="text" name="neprevazute"
                    value={neprevazute}/>
                    </p>


                    <p>
                        <input onClick={sumaZi}  className="margin-label-submit-conta-main" type="submit" value="Inregistreaza" />
                    </p>
                    </p>
                </div>
            </div>
        </div>
    )
}