import "./meniu.css"
import Navbar from "../menu/menu";
import insta from "../assets/insta.png";
import fb from "../assets/facebook.png"


export default function Meniu() {

    function moveToFb(){
        window.open ("https://m.facebook.com/RoastedCoffeeShop");
    }

    function moveToInsta(){
        window.open ("https://www.instagram.com/roasted.ro/");
    }


    return (
        <div>
            <Navbar/>
            <div className="menu-title">
                Meniu
            </div>
            <div className="menu-decorator"></div>
            <div className="menu-row">
                <div className="menu-cont" style={{backgroundColor: "black", color: "white"}}>
                    <div className="menu-cat">Black</div>
                    <div className="menu-cols">
                            <p className="menu-sizes">S    M    L    XL</p>
                            <div className="menu-items">
                                <p className="menu-item">Expresso</p>
                                <p className="menu-item">Doppio</p>
                                <p className="menu-item">Americano</p>
                                <p className="menu-item">Buzz Shot</p>
                                <p className="menu-item">Extra Shot</p>
                            </div>
                    </div>
                </div>
                <div className="menu-cont">
                    <div className="menu-cat">White</div>
                    <div className="menu-cols">
                        <p className="menu-sizes">S    M    L    XL</p>
                        <div className="menu-items">
                            <p className="menu-item">Cortado</p>
                            <p className="menu-item">Cafe Latte</p>
                            <p className="menu-item">Cappuccino</p>
                            <p className="menu-item">Flat White</p>
                            <p className="menu-item">Flavoured Cappucino / Latte</p>
                            <p className="menu-item">Alternative Milk</p>
                            <p className="menu-item">Extra Sirop</p>
                            <p className="menu-item">Extra Frisca</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="menu-row">
                <div className="menu-cont">
                    <div className="menu-cat">Not Coffee</div>
                    <div className="menu-cols">
                        <p className="menu-sizes">M    L</p>
                        <div className="menu-items">
                            <p className="menu-item">Hot Tea</p>
                            <p className="menu-item">Turmeric Latte</p>
                            <p className="menu-item">Hot Chocolate</p>
                            <p className="menu-item">Fog Tea Latte</p>
                            <p className="menu-item">Chicory Latte</p>
                            <p className="menu-item">Chai Tea Latte</p>
                            <p className="menu-item">Macha Latte</p>
                        </div>
                    </div>
                </div>
                <div className="menu-cont">
                    <div className="menu-cat">Froasted</div>
                    <div className="menu-cols">
                        <p className="menu-sizes">M    L</p>
                        <div className="menu-items">
                            <p className="menu-item">Expresso Tonic</p>
                            <p className="menu-item">Ice Cafe Latte</p>
                            <p className="menu-item">Ice Flavour Cafe Latte</p>
                            <p className="menu-item">Ice Coffee No Milk</p>
                            <p className="menu-item">Ice Caramel</p>
                            <p className="menu-item">Gelatto Cafe</p>
                            <p className="menu-item">Ice Pina Colada Cafe</p>
                            <p className="menu-item">Ice Mint Mojito Cafe</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="menu-row">
                <div className="menu-cont">
                    <div className="menu-cat">Flavours</div>
                    <p className="menu-item-center">Cappuccino / Latte</p>
                    <div className="menu-cols">
                        <p className="menu-sizes">S    M    L    XL</p>
                        <div className="menu-items">
                            <p style={{paddingTop: "100px"}} className="menu-item">After 8 Mint Cafe</p>
                            <p className="menu-item">Mochacino</p>
                            <p className="menu-item">Dark Mocha</p>
                            <p className="menu-item">Tiramisu Cafe</p>
                            <p className="menu-item">Cocoretto Latte</p>
                            <p className="menu-item">Amaretto Coffee</p>
                            <p className="menu-item">Jamaican Coffee</p>
                            <p className="menu-item">Coconut Latte</p>
                            <p className="menu-item">Royal Mint Cafe</p>
                        </div>
                    </div>
                </div>
                <div className="menu-cont">
                    <div className="menu-cat">Flavours</div>
                    <p className="menu-item-center">Cappuccino / Latte</p>
                                <div className="menu-cols">
                                    <p className="menu-sizes">S    M    L    XL</p>
                                    <div className="menu-items">
                                        <p style={{paddingTop: "100px"}} className="menu-item">Creme Brulee Cafe</p>
                                        <p className="menu-item">Macadamia Nut Cafe</p>
                                        <p className="menu-item">Macarons Cafe</p>
                                        <p className="menu-item">Pistachio Cafe</p>
                                        <p className="menu-item">Roasted Special</p>
                                        <p className="menu-item">Hazel Nut Latte</p>
                                        <p className="menu-item">Caramel Vanilla Cafe</p>
                                        <p className="menu-item">Irish Coffee</p>
                                        <p className="menu-item">Crunch My Toffee Coffee</p>
                                        <p className="menu-item">Salty Caramel Cafe</p>
                                    </div>
                                </div>
                </div>
            </div>
            <div className="menu-row">
                <div className="menu-cont">
                    <div className="menu-cat">Adauga Gratuit</div>
                    <div className="menu-cols1">
                        <div className="menu-col21">
                            <div className="menu-items1">
                                <p className="menu-item2">Zahar Alb</p>
                                <p className="menu-item2">Chef de Munca</p>
                                <p className="menu-item2">Zaharina</p>
                                <p className="menu-item2">Pudra de Ghimbir</p>
                                <p className="menu-item2">Buna Dispozitie</p>
                                <p className="menu-item2">Nucsoara</p>
                                <p className="menu-item2">Cocos</p>
                            </div>
                        </div>
                        <div className="menu-col21">
                            <p className="menu-sizes2">Zahar Brun</p>
                            <p className="menu-sizes2">Stevia</p>
                            <p className="menu-sizes2">Cayenne Pepper</p>
                            <p className="menu-sizes2">Scortisoara</p>
                            <p className="menu-sizes2">Cardamon</p>
                            <p className="menu-sizes2">Turmeric</p>
                            <p className="menu-sizes2">Cacao</p>
                        </div>
                    </div>
                </div>
                <div className="menu-cont">
                    <div className="menu-cat">Coffee House Blend</div>
                    <p className="menu-item-center">Blend 16</p>
                    <div className="menu-cols">
                        <p className="menu-sizes">250g  /  1kg</p>
                        <div className="menu-col1">
                            <div className="menu-items">
                                <p className="menu-item">Blend 16</p>
                                <p className="menu-item">Columbia</p>
                                <p className="menu-item">Guatemala</p>
                                <p className="menu-item">Nicaragua</p>
                                <p className="menu-item">Honduras</p>
                                <p className="menu-item">India</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="menu-socials">
                <div className="menu-socials-container">
                    <img className="socials-icon" src={insta} alt="no insta logo" onClick={moveToInsta}/>
                </div>
                <div className="menu-socials-container">
                    <img className="socials-icon" src={fb} alt="no insta logo" onClick={moveToFb}/>
                </div>
            </div>
            <div className="menu-credits">
                Copyright 2023 © Roasted Coffee Shop
            </div>
        </div>
    )
}