import Plot from 'react-plotly.js'
import React, {useEffect} from "react";
import "./conta.css"
import "./plot.css"
import Item from "./Item";
import {useState} from "react";
import Navbar from "../menu/menu";
import DropdownButton from './dropdown';
import "./meniu.css"
import { API_KEY } from './config';
export default function PlotChart(props)
{


    const [cashAndCard, setCashAndCard] = useState([10,20]);
    const [chart, setChart] = useState(0)
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [x1, setX1] = useState(0)
    const [y1, setY1] = useState(0)
    const [totalVanzari, setTotalVanzari] = useState(0)
    const [cafeaChart, setCafeaChart] = useState(10)
    const [lapteChart, setLapteChart] = useState(10)
    const [alternativChart, setAlternativChart] = useState(10)
    const [cashChart, setCashChart] = useState(10)
    const [cardChart, setCardChart] = useState(10)
    const [cafeaChart1, setCafeaChart1] = useState(10)
    const [lapteChart1, setLapteChart1] = useState(10)
    const [alternativChart1, setAlternativChart1] = useState(10)
    const [cashChart1, setCashChart1] = useState(10)
    const [cardChart1, setCardChart1] = useState(10)
    const [cafeaChart2, setCafeaChart2] = useState(10)
    const [lapteChart2, setLapteChart2] = useState(10)
    const [alternativChart2, setAlternativChart2] = useState(10)
    const [cashChart2, setCashChart2] = useState(10)
    const [cardChart2, setCardChart2] = useState(10)
    const [dataChart, setdataChart] = useState(0)
    const [cafeaChartTot, setCafeaChartTot] = useState(10)
    const [lapteChartTot, setLapteChartTot] = useState(10)
    const [alternativChartTot, setAlternativChartTot] = useState(10)
    const [cashChartTot, setCashChartTot] = useState(10)
    const [cardChartTot, setCardChartTot] = useState(10)
    const [dataChart1, setdataChart1] = useState(0)
    const [dataChart2, setdataChart2] = useState(0)
    const labels = ['Cafea', 'Lapte', 'Alternativ']
    const labels1 = ['Cash', 'Card']
    const labels2 = ['Incasari/Lapte', 'Incasari/Cafea - 4%']
    const options = ['Consumatie Batistei', 'Consumatie Italiana', 'Consumatie Lahovari', "Consumatie Tot"];
    const options1 = ['Incasari Batistei', 'Incasari Italiana', 'Incasari Lahovari', "Incasari Tot"];
    const luni = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const luni_str = ['01', '02','03', '04','05', '06','07', '08','09', '10','11', '12']
    const zile = [31,28,31,30,31,30,31,31,30,31,30,31]
    const options2 = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [selectedOption, setSelectedOption] = React.useState(options[0]);
    const [selectedOption1, setSelectedOption1] = React.useState(options1[0]);
    const [selectedOption2, setSelectedOption2] = React.useState(options2[0]);
    const [sumMonth, setSumMonth] = useState(0)
    const [sumMonthCard, setSumMonthCard] = useState(0)
    const [sumMonthCash, setSumMonthCash] = useState(0)
    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        // Handle option selection logic
    };

    const handleOptionSelect1 = (option) => {
        setSelectedOption1(option);
    };


    const handleOptionSelect2 = (option) => {
        setSelectedOption2(option);
    };



    let trace3 = {
        x: ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'],
        y: [100, 110, 90, 60, 120, 150, 90],
        type: 'bar',
        name: 'Cash',
        // text: y.map(String),
        // textposition: 'auto',
        // hoverinfo: 'none',
        marker: {
            color: 'rgb(46,180,46)',
            opacity: 1,
        }
    };

    let trace4 = {
        x: ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'],
        y: [100, 110, 90, 60, 120, 150, 90],
        type: 'bar',
        name: 'Card',
        // text: y.map(String),
        // textposition: 'auto',
        // hoverinfo: 'none',
        marker: {
            color: 'rgb(15,63,222)',
            opacity: 1,
        }
    };

    let trace5 = {
        x: ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'],
        y: [100, 110, 90, 60, 120, 150, 90],
        type: 'bar',
        name: 'Card',
        // text: y.map(String),
        // textposition: 'auto',
        // hoverinfo: 'none',
        marker: {
            color: 'rgb(15,222,222)',
            opacity: 1,
        }
    };

    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [specificItem, setSpecificItem] = useState(null);
    const [inputValue1, setInputValue1] = useState('');
    const [specificItem1, setSpecificItem1] = useState(null);
    const [inputValue2, setInputValue2] = useState('');
    const [specificItem2, setSpecificItem2] = useState(null);

    useEffect(() => {
        fetch('https://roasted.ro/batistei.php',  {
            headers: {
                Authorization: 'cafelutza' // Replace 'your-token-here' with your actual token
            }
        })

            .then((response) => response.json())
            .then((jsonData) => {
                setData(jsonData);
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    useEffect(() => {
        fetch('https://roasted.ro/italiana.php', {
            headers: {
                Authorization: 'cafelutza' // Replace 'your-token-here' with your actual token
            }
        })
            .then((response) => response.json())
            .then((jsonData) => {
                setData1(jsonData);
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    useEffect(() => {
        fetch('https://roasted.ro/lahovari.php', {
            headers: {
                Authorization: 'cafelutza' // Replace 'your-token-here' with your actual token
            }
        })
            .then((response) => response.json())
            .then((jsonData) => {
                setData2(jsonData);
            })
            .catch((error) => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputChange1 = (event) => {
        setInputValue1(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (selectedOption == 'Consumatie Batistei') {
            const foundItem = data.find((item) => item.data === inputValue);
            setSpecificItem(foundItem);
            if (specificItem != null)
            {
                setCafeaChartTot(specificItem.cafea)
                setLapteChartTot(specificItem.lapte)
                setAlternativChartTot(specificItem.lapte_alternativ)
                setdataChart(specificItem.data)
                setCashChartTot(specificItem.cash)
                setCardChartTot(specificItem.card)
            }
        }

        else if (selectedOption == 'Consumatie Italiana') {
            const foundItem1 = data1.find((item) => item.data === inputValue);
            setSpecificItem(foundItem1);
            if (specificItem != null)
            {
                setCafeaChartTot(specificItem.cafea)
                setLapteChartTot(specificItem.lapte)
                setAlternativChartTot(specificItem.lapte_alternativ)
                setdataChart(specificItem.data)
                setCashChartTot(specificItem.cash)
                setCardChartTot(specificItem.card)
            }
        }

        else if (selectedOption == 'Consumatie Lahovari') {
            const foundItem2 = data2.find((item) => item.data === inputValue);
            setSpecificItem(foundItem2);
            if (specificItem != null)
            {
                setCafeaChartTot(specificItem.cafea)
                setLapteChartTot(specificItem.lapte)
                setAlternativChartTot(specificItem.lapte_alternativ)
                setdataChart(specificItem.data)
                setCashChartTot(specificItem.cash)
                setCardChartTot(specificItem.card)
            }
        }

        else if (selectedOption == 'Consumatie Tot') {
            const foundItem = data.find((item) => item.data === inputValue);
            setSpecificItem(foundItem);
            const foundItem2 = data2.find((item) => item.data === inputValue);
            setSpecificItem2(foundItem2);
            const foundItem1 = data1.find((item) => item.data === inputValue);
            setSpecificItem1(foundItem1);

            if (specificItem === undefined) {
                setCafeaChart(0)
                setLapteChart(0)
                setAlternativChart(0)
                setCashChart(0)
                setCardChart(0)
                console.log("Este null batistei")
            }

            else {
                setCafeaChart(specificItem.cafea)
                setLapteChart(specificItem.lapte)
                setAlternativChart(specificItem.lapte_alternativ)
                setCashChart(specificItem.cash)
                setCardChart(specificItem.card)
            }

            if (specificItem1 === undefined) {
                setCafeaChart1(0)
                setLapteChart1(0)
                setAlternativChart1(0)
                setCashChart1(0)
                setCardChart1(0)
                console.log("Este null italiana")
            }

            else{
                setCafeaChart1(specificItem1.cafea)
                setLapteChart1(specificItem1.lapte)
                setAlternativChart1(specificItem1.lapte_alternativ)
                setCashChart1(specificItem1.cash)
                setCardChart1(specificItem1.card)
            }

            if (specificItem2 === undefined) {
                setCafeaChart2(0)
                setLapteChart2(0)
                setAlternativChart2(0)
                setCashChart2(0)
                setCardChart2(0)
                console.log("Este null lahovari")
            }

            else {
                setCafeaChart2(specificItem2.cafea)
                setLapteChart2(specificItem2.lapte)
                setAlternativChart2(specificItem2.lapte_alternativ)
                setCashChart2(specificItem2.cash)
                setCardChart2(specificItem2.card)
            }
                console.log(cafeaChart)
                console.log(cafeaChart1)
                console.log(cafeaChart2)
                setCafeaChartTot((parseInt(cafeaChart)+parseInt(cafeaChart1)+parseInt(cafeaChart2)))
                setLapteChartTot((parseInt(lapteChart)+parseInt(lapteChart1)+parseInt(lapteChart2)))
                setAlternativChartTot((parseInt(alternativChart)+parseInt(alternativChart1)+parseInt(alternativChart2)))
                setCashChartTot((parseInt(cashChart)+parseInt(cashChart1)+parseInt(cashChart2)))
                setCardChartTot((parseInt(cardChart)+parseInt(cardChart1)+parseInt(cardChart2)))
        }

    };

    const handleSubmit2 = (event) => {
        event.preventDefault();
        console.log("submited " + selectedOption2.toString())
        const index = zile[luni.findIndex((element) => element === selectedOption2)];
        console.log(index);
        let arrChart = [];
        let arrDates = [];
        let arrChart1 = [];
        let arrDates1 = [];
        let arrTotal = [];


        for(let i=1; i<=index; i++) {
            arrDates.push(i)
            if(i<10) {
                let calendar = inputValue1.toString() + "-" + luni_str[luni.findIndex((element) => element === selectedOption2)].toString() + "-0" + i.toString()
                console.log(calendar)
                let foundItem = 0;
                let foundItem1 = 0;
                let foundItem2 = 0;
                let sumCash = 0;
                let sumCard = 0;
                let sumTotal = 0;
                if (selectedOption1 == 'Incasari Batistei') {
                    foundItem = data.find((item) => item.data == calendar);
                }

                else if (selectedOption1 == 'Incasari Italiana') {
                    foundItem = data1.find((item) => item.data == calendar);
                }

                else if (selectedOption1 == 'Incasari Lahovari') {
                    foundItem = data2.find((item) => item.data == calendar);
                }

                else if (selectedOption1 == 'Incasari Tot') {
                    foundItem = data.find((item) => item.data === calendar);
                    foundItem2 = data2.find((item) => item.data === calendar);
                    foundItem1 = data1.find((item) => item.data === calendar);

                    if (foundItem === undefined) {
                        sumCash += 0
                        sumCard += 0
                        sumTotal += 0
                        console.log("Este null batistei")
                    }

                    else {
                        sumCash += parseInt(foundItem.cash)
                        sumCard += parseInt(foundItem.card)
                        sumTotal += parseInt(foundItem.card) + parseInt(foundItem.card)
                    }

                    if (foundItem1 === undefined) {
                        sumCash += 0
                        sumCard += 0
                        sumTotal += 0
                        console.log("Este null Italiana")
                    }

                    else {
                        sumCash += parseInt(foundItem1.cash)
                        sumCard += parseInt(foundItem1.card)
                        sumTotal += parseInt(foundItem1.card) + parseInt(foundItem1.card)
                    }

                    if (foundItem2 === undefined) {
                        sumCash += 0
                        sumCard += 0
                        sumTotal += 0
                        console.log("Este null Italiana")
                    }

                    else {
                        sumCash += parseInt(foundItem2.cash)
                        sumCard += parseInt(foundItem2.card)
                        sumTotal += parseInt(foundItem2.card) + parseInt(foundItem2.card)
                    }

                    console.log(parseInt(cashChart)+parseInt(cashChart1)+parseInt(cashChart2))
                    arrChart.push(sumCash)
                    arrChart1.push(sumCard)
                    arrTotal.push(sumTotal)
                }


                if (foundItem != null && selectedOption1 != 'Incasari Tot')
                {
                    console.log(parseInt(foundItem.cash))
                    arrChart.push(parseInt(foundItem.cash))
                    arrChart1.push(parseInt(foundItem.card))
                    arrTotal.push(parseInt(foundItem.card)+parseInt(foundItem.cash))
                }
                else if (foundItem == null && selectedOption1 != 'Incasari Tot'){
                    arrChart.push(0)
                    arrChart1.push(0)
                    arrTotal.push(0)
                }
            }
            else{
                let calendar = inputValue1.toString() + "-" + luni_str[luni.findIndex((element) => element === selectedOption2)].toString() + "-" + i.toString()
                console.log(calendar)
                let foundItem = 0;
                let foundItem1 = 0;
                let foundItem2 = 0;
                let sumCash = 0;
                let sumCard = 0;
                let sumTotal = 0;
                if (selectedOption1 == 'Incasari Batistei') {
                    foundItem = data.find((item) => item.data == calendar);
                }

                else if (selectedOption1 == 'Incasari Italiana') {
                    foundItem = data1.find((item) => item.data == calendar);
                }

                else if (selectedOption1 == 'Incasari Lahovari') {
                    foundItem = data2.find((item) => item.data == calendar);
                }

                else if (selectedOption1 == 'Incasari Tot') {
                    foundItem = data.find((item) => item.data === calendar);
                    foundItem2 = data2.find((item) => item.data === calendar);
                    foundItem1 = data1.find((item) => item.data === calendar);

                    if (foundItem === undefined) {
                        sumCash += 0
                        sumCard += 0
                        sumTotal += 0
                        console.log("Este null batistei")
                    } else {
                        sumCash += parseInt(foundItem.cash)
                        sumCard += parseInt(foundItem.card)
                        sumTotal += parseInt(foundItem.card) + parseInt(foundItem.card)
                    }

                    if (foundItem1 === undefined) {
                        sumCash += 0
                        sumCard += 0
                        sumTotal += 0
                        console.log("Este null Italiana")
                    } else {
                        sumCash += parseInt(foundItem1.cash)
                        sumCard += parseInt(foundItem1.card)
                        sumTotal += parseInt(foundItem1.card) + parseInt(foundItem1.card)
                    }

                    if (foundItem2 === undefined) {
                        sumCash += 0
                        sumCard += 0
                        sumTotal += 0
                        console.log("Este null Italiana")
                    } else {
                        sumCash += parseInt(foundItem2.cash)
                        sumCard += parseInt(foundItem2.card)
                        sumTotal += parseInt(foundItem2.card) + parseInt(foundItem2.card)
                    }


                    arrChart.push(sumCash)
                    arrChart1.push(sumCard)
                    arrTotal.push(sumTotal)
                    console.log(sumTotal)
                }

                if (foundItem != null && selectedOption1 != 'Incasari Tot')
                {
                    console.log(parseInt(foundItem.cash))
                    arrChart.push(parseInt(foundItem.cash))
                    arrChart1.push(parseInt(foundItem.card))
                    arrTotal.push(parseInt(foundItem.card)+parseInt(foundItem.cash))
                }
                else if (foundItem == null && selectedOption1 != 'Incasari Tot'){
                    arrChart.push(0)
                    arrChart1.push(0)
                    arrTotal.push(0)
                }
            }
        }
        console.log(arrChart)
        console.log(arrDates)
        setX(arrDates)
        setX1(arrDates)
        setY(arrChart)
        setY1(arrChart1)
        setTotalVanzari((arrTotal))
        setSumMonthCash(arrChart.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
        setSumMonthCard(arrChart1.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
        setSumMonth(arrTotal.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
    };

    trace3.x = x
    trace3.y = y
    trace4.x = x1
    trace4.y = y1
    trace5.x = x
    trace5.y = totalVanzari



    return(
        <div className="stats">
            <Navbar/>
            <div className="chart-row">
                <div className="option-btn">
                    <DropdownButton
                        options={options}
                        selectedOption={selectedOption}
                        onSelect={handleOptionSelect}
                    />
                </div>
                <div>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="date"
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder="Enter a value"
                        />
                        <button type="submit">Submit</button>
                    </form>
                    {specificItem && (
                        <div>
                            <br/>
                            <div>Data: {specificItem.data}</div>
                            <div>Cafea: {cafeaChartTot}</div>
                            <div>Lapte: {lapteChartTot}</div>
                            <div>Alternativ: {alternativChartTot}</div>
                            <div>Cash: {cashChartTot}</div>
                            <div>Card: {cardChartTot}</div>
                        </div>
                    )}
                    {/*<Plot*/}
                    {/*    data={[*/}
                    {/*        {*/}
                    {/*            values: [cafeaChart, lapteChart, alternativChart],*/}
                    {/*            labels: labels ,*/}
                    {/*            type: 'pie'*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*    layout={ {width: 500, height: 450, title: "Consumatie " + dataChart.toString()} }*/}
                    {/*/>*/}
                </div>
                <div className="chart-row">
                    <Plot
                        data={[
                            {
                                values: [cashChartTot, cardChartTot],
                                labels: labels1,
                                type: 'pie',
                                // text: labels1.map((label, index) => `${label}: ${cashAndCard[1]} `),
                                // Construct the text to display values and percentages // Construct the text to display values and percentages
                                text: [cashChartTot, cardChartTot],
                                textposition: 'inside',
                                hoverinfo: 'text',
                                marker: {
                                    colors: ['green', 'blue'], // Set custom colors for the pie slices
                                },
                            },
                        ]}
                        layout={{ width: 500, height: 450, title: "Incasari " + dataChart.toString() }}
                    />
            </div>
            </div>
                {/*) :*/}
            <div className="chart-row">
                <p>Incasari/Lapte: {((parseInt(cashChart)+parseInt(cardChart))/(parseInt(lapteChart)+parseInt(alternativChart))).toFixed(2)}</p>
                <br/>
                <p>Incasari/Cafea - 4%: {((parseInt(cashChart)+parseInt(cardChart))/(parseInt(cafeaChart)/8)*0.96).toFixed(2)}</p>
                {/*<Plot*/}
                {/*    data={[*/}
                {/*        {*/}
                {/*            values: [(parseInt(cashChart)+parseInt(cardChart))/(parseInt(lapteChart)+parseInt(alternativChart)), (parseInt(cashChart)+parseInt(cardChart))/(parseInt(cafeaChart)/8)*0.96],*/}
                {/*            labels: labels2 ,*/}
                {/*            type: 'pie'*/}
                {/*        },*/}
                {/*    ]}*/}
                {/*    layout={ {width: 500, height: 450, title: "Formula " + dataChart.toString()} }*/}
                {/*/>*/}
                {/*}*/}
            </div>
                <div className="chart-row">

                    <form onSubmit={handleSubmit2}>
                        <div className="option-btn">
                            <DropdownButton
                                options={options1}
                                selectedOption={selectedOption1}
                                onSelect={handleOptionSelect1}
                            />
                        </div>
                        <div className="option-btn">
                            <DropdownButton
                                options={options2}
                                selectedOption={selectedOption2}
                                onSelect={handleOptionSelect2}
                            />
                        </div>
                        <input
                            type="text"
                            value={inputValue1}
                            onChange={handleInputChange1}
                            placeholder="Year"
                        />
                        <button type="submit">Submit</button>
                    </form>


                    <div className="center-info">
                        <p>Total Cash: {sumMonthCash}</p>
                        <p>Total Card: {sumMonthCard}</p>
                        <p>Total Incasari: {sumMonth}</p>
                    </div>

                    <Plot

                        data = { [trace3, trace4]}

                        layout = {{
                            title:  'Vanzari ' + selectedOption2 + " " + inputValue1 + "\n"
                                + "Total Cash: " +  sumMonthCash + "\n"
                                + "Total Card: " +  sumMonthCard + "\n"
                                + "Total Incasari: " +  sumMonth + "\n",
                            xaxis: {
                                tickangle: -45
                            },
                            barmode: 'group',

                        }}
                    />

                    <Plot

                        data = { [trace5]}

                        layout = {{
                            title:  'Vanzari ' + selectedOption2 + " " + inputValue1,
                            xaxis: {
                                tickangle: -45
                            },
                            barmode: 'group',

                        }}
                    />
                </div>

        </div>
    )
}