import "./contact.css"
import insta from "../assets/insta.png"
import fb from "../assets/facebook.png"


export default function Contact()
{
    function moveToFb(){
        window.open ("https://m.facebook.com/RoastedCoffeeShop");
    }

    function moveToInsta(){
        window.open ("https://www.instagram.com/roasted.ro/");
    }

    return (
        <div className="contact">
            <div className="contact-title">
                <p>Contact</p>
            </div>
            <div className="contact-decorator"></div>
            <div className="contact-email">
                <p className="contact-info">
                    <a href="mailto:coffeeshopraosted@gmail.com">
                        email: coffeeshopraosted@gmail.com
                    </a>
                </p>
                <p className="contact-info">
                    <a href="tel:+40721497161">
                        tel: 0721.497.161
                    </a>
                </p>
            <div className="socials">
                <div className="socials-container">
                    <img className="socials-icon" src={insta} alt="no insta logo" onClick={moveToInsta}/>
                </div>
                <div className="socials-container">
                    <img className="socials-icon" src={fb} alt="no fb logo" onClick={moveToFb}/>
                </div>
            </div>
                <div className="credits">
                    Copyright 2023 © Roasted Coffee Shop
                </div>
            </div>
        </div>
    )
}