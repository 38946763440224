import "./admin.css"
import Navbar from "../menu/menu";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import React from "react";
import axios from "axios";


export default function Admin()
{
    const [id, setId] = useState("")
    const [pass, setPass] = useState("")
    const navigate = useNavigate()

    const changeId = e => {
        setId(e.target.value)
    }

    const changePass = e => {
        setPass(e.target.value)
    }

    const checkPass = () => {
        if(id==="roasted" && pass==="roasted123"){
            navigate('/conta')
            console.log("Id si Parola corecte")


        }
        else{
            alert("Credentiale gresite!")
        }
    }

    const [inputs, setInputs] = useState()

    const handelSubmit = (event) => {
        event.preventDefault()

        axios.post('http://localhost:2083/api/user/save', inputs);
        console.log(inputs)
    }

    // console.log(id)
    // console.log(pass)


    return(
        <div>
            <Navbar/>
           <div className="admin-login">
                <div className="login-title">
                    Login Page
                </div>
               <div className="login-line"></div>
               <div className="login-content">
                   <p>
                       <label className="padding-label1">
                           Id:
                       </label>
                       <input onChange={changeId} className="margin-label" type="text" name="name"
                       value={id}/>
                   </p>
                   <p>
                       <label className="padding-label">
                           Pass:
                       </label>
                       <input onChange={changePass} className="margin-label" type="password" name="name"
                       value={pass}/>
                   </p>
                   <p>
                       <input onClick={checkPass} className="margin-label-submit" type="submit" value="Login" />
                   </p>
               </div>
           </div>
        </div>
    )
}