import React from 'react';

const DropdownButton = ({ options, selectedOption, onSelect }) => {
    return (
        <select
            className="dropdown-button"
            value={selectedOption}
            onChange={(e) => onSelect(e.target.value)}
        >
            {options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};

export default DropdownButton;
