import logo from './logo.svg';
import loading from './assets/Untitled.mp4'
import './App.css';

import Menu from "./menu/menu";
import Header from "./pages/header";
import Locatii from "./pages/locatii";
import Contact from "./pages/contact";
import Loading from "./pages/loading";

function App() {


  return (
      <div className="App">
    <Menu/>
          {/*<Loading/>*/}
        <Header/>
          <Locatii/>
        <Contact/>
      </div>
  );
}

export default App;
