import "./header.css"
import logo from "../assets/Logo.png"
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";

export default function Header() {

    function moveToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    function moveToMeniu() {
        moveToTop();
    }

    function moveToContact() {
        window.scrollTo(0,2500);
    }

    function moveToLocatii() {
        window.scrollTo(0,1100);
    }

    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpacity(1);
        }, 3000);
    }, []);

    return(
        <center>
        <div className="roasted-logo-div">
            {/*style={{opacity}}*/}
            <div className="roasted-logo-img">
                <img className="roasted-logo" src={logo}/>
            </div>
            <div className="menu-3">
                <Link to="/franciza" onClick={moveToTop} >
                <div className="button-menu-header" onClick={moveToTop}>
                    <p className="title-menu-header" onClick={moveToTop}>Franciza</p>
                </div>
                </Link>
                <div className="button-menu-header" onClick={moveToLocatii}>
                    <p className="title-menu-header" onClick={moveToLocatii}>Locatii</p>
                </div>
                <Link to="/meniu" onClick={moveToMeniu} >
                        <div className="button-menu-header" onClick={moveToMeniu}>
                            <p className="title-menu-header" onClick={moveToMeniu}>Meniu</p>
                        </div>
                </Link>


            </div>
            <div className="menu-2">
                <Link to="/microprajitorie" onClick={moveToTop} >
                <div className="button-menu-header" onClick={moveToTop}>

                        <p className="title-menu-header" onClick={moveToTop}>Microprajitorie</p>
                </div>
                </Link>
                <div className="button-menu-header" onClick={moveToContact}>
                    <p className="title-menu-header" onClick={moveToContact}>Contact</p>
                </div>
            </div>
            <div>
                <div className="space-sep"></div>
            </div>
            <Link to="/meniu" onClick={moveToMeniu}>
                <div className="menu-btn">
                    <div className="menu-button-text">
                           Meniu
                        <div className="menu-decorator"></div>
                    </div>
                </div>
            </Link>
        </div>
        </center>
    )
}