import "./conta.css"
import Navbar from "../menu/menu";
import {useState} from "react";
import Items from "./items";
import {useNavigate} from "react-router-dom";
export default function Conta() {


    const currentDate = new Date().toISOString().split('T')[0];
    const submitText = "Inregistreaza"

    const [data, setData] = useState(currentDate)
    const [cafea, setCafea] = useState()
    const [lapte, setLapte] = useState()
    const [alternativ, setAlternativ] = useState()
    const [cash, setCash] = useState()
    const [card, setCard] = useState()
    let suma = 0
    const navigate = useNavigate()

    const changeData = e => {
        setData(e.target.value)
    }

    const changeCafea = e => {
        setCafea(e.target.value)
    }

    const changeLapte = e => {
        setLapte(e.target.value)
    }

    const changeAlternativ = e => {
        setAlternativ(e.target.value)
    }

    const changeCash = e => {
        setCash(e.target.value)
    }

    const changeCard = e => {
        setCard(e.target.value)
    }

    const sumaZi = () => {
        suma += (cafea + lapte + alternativ)
        console.log(suma)
        navigate('/plot')
    }


    return(
        <div>
            <Navbar/>
            <div className="conta-login">
                <div className="conta-title-batistei">
                    Contabilitate Batistei
                </div>
                <div className="conta-line"></div>
                <div className="conta-content-batistei">
                    <form action="https://roasted.ro/insert.php" method="post">
                        <p className="no-wrap-data">
                            <label className="label-conta-batistei-data">
                                Data
                            </label>
                            <input onChange={changeData} className="margin-label-conta" type="date" name="data" defaultValue={currentDate}
                                   value={data}/>
                        </p>
                        <div className="conta-line-data"></div>
                    <p className="no-wrap">
                        <label className="label-conta-batistei-cafea">
                            Cafea
                        </label>
                        <input onChange={changeCafea} className="margin-label-conta" type="text" name="cafea"
                               value={cafea}/>
                    </p>
                    <p className="no-wrap">
                        <label className="label-conta-batistei-lapte">
                            Lapte
                        </label>
                        <input onChange={changeLapte} className="margin-label-conta" type="int" name="lapte"
                               value={lapte}/>
                    </p>
                    <p className="no-wrap">
                        <label className="label-conta-batistei-alt">
                            Alternativ
                        </label>
                        <input onChange={changeAlternativ} className="margin-label-conta" type="int" name="lapte_alternativ"
                               value={alternativ}/>
                    </p>
                    <p className="no-wrap">
                        <label className="label-conta-batistei-cash">
                            Cash
                        </label>
                        <input onChange={changeCash} className="margin-label-conta" type="int" name="cash"
                               value={cash}/>
                    </p>

                    <p className="no-wrap">
                        <label className="label-conta-batistei-card">
                            Card
                        </label>
                        <input onChange={changeCard} className="margin-label-conta" type="int" name="card"
                               value={card}/>
                    </p>
                        <br/>
                    <p>
                        <button type="submit" className="margin-label-submit-conta">Inregistreaza</button>
                        {/*<input  className="margin-label-submit-conta" type="submit" value={submitText} />*/}
                    </p>
                    </form>
                </div>
            </div>
        </div>
    )
}